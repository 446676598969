<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <div class="text-center">
      <h4 class="mt-5">
        تحديث المقال

        <feather-icon icon="Edit2Icon" />
      </h4>

    </div>
    <validation-observer ref="UpdateBlogFormvalidate">
      <b-form
        ref="form"
        class="mt-2"
        style="width: 100%"
        @submit.prevent="save"
      >
        <b-row>
          <!-- <b-col md="4">
              <b-form-group
                label="Category"
                label-for="blog-edit-category"
                class="mb-2"
              >
                 <validation-provider
                    #default="{ errors }"
                    name="Category"
                    rules=""
                  >
                <v-select
                  id="blog-edit-category"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  v-model="UpdateBlogForm.category_id"
                  label="type"
                  :options="categoriesList"
                  :reduce="(val) => val.id"

                />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col> -->
          <b-col md="4">
            <b-form-group
              label="العنوان"
              label-for="blog-edit-title"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Title"
                rules="required"
              >
                <b-form-input
                  id="blog-edit-title"
                  v-model="UpdateBlogForm.title"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="عرض في"
              label-for="blog-edit-category"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Show In"
                rules=""
              >
                <v-select
                  id="blog-edit-category"
                  v-model="UpdateBlogForm.type"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="label"
                  :options="showList"
                  :reduce="(val) => val.id"
                  @input="getNews"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="العنوان الفرعي"
              label-for="blog-edit-slug"
              class="mb-2"
            >

              <validation-provider
                #default="{ errors }"
                name="Sub Title"
                rules="required"
              >
                <b-form-input
                  id="blog-edit-slug"
                  v-model="UpdateBlogForm.subtitle"
                  @input="removeSpace($event)"
                />

              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="الرابط "

              label-for="blog-edit-category"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="YouTube Link"
                rules="required"
              >
                <b-form-input
                  id="videor_link"
                  v-model="UpdateBlogForm.slug"
                  @input="removeSpace($event)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <p v-if="UpdateBlogForm.type==1">الرابط بعد الانشاء{{ `https://api.ar.lpcentre.com/articles/${UpdateBlogForm.slug }` }}</p> 
<p v-else>الرابط بعد الانشاء{{ `https://ar.lpcentre.com/news/${UpdateBlogForm.slug }` }}</p>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            class="mb-2"
          >
            <div class="border rounded p-2">
              <h4 class="mb-1">
                Tab صورة
              </h4>
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row"
              >
                <div class="d-inline-block">
                  <b-media-aside>
                    <b-img
                      ref="refPreviewE2"
                      :src="`https://ar.lpcentre.com/${UpdateBlogForm.tab_image}`"
                      height="110"
                      width="170"
                      class="rounded mr-2 mb-1 mb-md-0"
                    />
                  </b-media-aside>

                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    class="rounded ml-1 mt-2 text-center"
                    @click="refPreviewE2.src = Tabimage
                            UpdateBlogForm.tab_image=''
                    "
                  >
                    ازالة الصورة
                  </b-button>
                </div>
                <b-media-body>
                  <b-card-text class="mt-1" />
                  <div class="d-inline-block  mt-1">
                    <b-form-group

                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Tab Image "
                        rules="required"
                      >
                        <b-form-file
                          ref="refInputE2"
                          v-model="UpdateBlogForm.tab_image"
                          accept=".jpg, .png, .gif, .jpeg"
                          placeholder="اختر ملف"
                          @input="inputImageRendererTab"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      label="نص بديل للصورة"
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <b-form-input
                        id="alt-image"
                        v-model="UpdateBlogForm.alt_tab_image"
                      />
                    </b-form-group>
                  </div>
                </b-media-body>
              </b-media>
            </div>
            <div class="border rounded p-2">
              <h4 class="mb-1">
                Header صورة
              </h4>
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row"
              >
                <div class="d-inline-block">
                  <b-media-aside>
                    <b-img
                      ref="refPreviewEl"
                      :src="`https://ar.lpcentre.com/${UpdateBlogForm.header_image}`"
                      height="110"
                      width="170"
                      class="rounded mr-2 mb-1 mb-md-0"
                    />
                  </b-media-aside>
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    class="rounded ml-1 mt-2 text-center"
                    @click="refPreviewEl.src = Tabimage
                            UpdateBlogForm.header_image=''
                    "
                  >
                    ازالة الصورة
                  </b-button>
                </div>
                <b-media-body>
                  <b-card-text class="mt-1" />
                  <div class="d-inline-block  mt-1">
                    <b-form-group
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Header Image "
                        rules="required"
                      >
                        <b-form-file
                          ref="refInputEl"
                          v-model="UpdateBlogForm.header_image"
                          accept=".jpg, .png, .gif, .jpeg"
                          placeholder="اختر ملف"
                          @input="inputImageRenderer"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      label="نص بديل للصورة"
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <b-form-input
                        id=" alt_header_image"
                        v-model="UpdateBlogForm.alt_header_image"
                      />
                    </b-form-group>
                  </div>
                </b-media-body>
              </b-media>
            </div>
          </b-col>
          <b-col md="6" v-if="UpdateBlogForm.type==1">
            <b-form-group
              label="فئة المقال "
              label-for="blog-edit-course"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Courses"
                rules=""
              >
                <v-select
                  id="blog-edit-category"
                  v-model="UpdateBlogForm.category_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="type"
                  :options="categoriesList"
                  :reduce="(val) => val.id"
                  @input="getCoursesByCategory(UpdateBlogForm.category_id)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6" v-if="UpdateBlogForm.type==1">
            <b-form-group
              label="الدورات المرتبطة "
              label-for="blog-edit-course"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Courses"
                rules=""
              >
                <v-select
                  id="blog-edit-category"
                  v-model="UpdateBlogForm.related_courses"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="coursesList"
                  multiple
                  :reduce="(val) => val.id"
                >
                  <template
                    v-if="!getCourse"
                    #no-options="{ search, searching, loading }"
                  >
                    اختر الفئة اولا ...
                  </template>
                  <template
                    v-else
                    #no-options="{ search, searching, loading }"
                  >
                    جار التحميل ...
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12" >
            <b-form-group
              label="المقالات المرتبطة "
              label-for="blog-edit-course"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Courses"
                rules=""
              >
                <v-select
                  id="blog-edit-category"
                  v-model="UpdateBlogForm.related_posts"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  multiple
                  :options="articleByCategoryList"
                  :reduce="(val) => val.id"
                >
                  <template
                    v-if="!getCourse"
                    #no-options="{ search, searching, loading }"
                  >
                    اختر الفئة اولا ...
                  </template>
                  <template
                    v-else
                    #no-options="{ search, searching, loading }"
                  >
                    جار التحميل ...
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="التفاصيل"
              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Details"
                rules="required"
              >
               <QuillEditor
                  :editorRef="'descriptionEditor'"
             v-model="UpdateBlogForm.details"
                />
             
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            class="mt-50"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              حفظ
            </b-button>

          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'

import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref} from '@vue/composition-api'
import Vue from 'vue'
import Editor from '@tinymce/tinymce-vue'
import { useRouter } from '@core/utils/utils'
import store from '@/store'
import QuillEditor from "@core/components/editor/Editor";
export default {
  setup() {
    // const store = useStore();
    const refInputEl = ref(null)
    const refInputE2 = ref(null)
    const refPreviewEl = ref(null)
    const refPreviewE2 = ref(null)
    const getCourse = ref(false)
    const coursesList = ref([])
    const Form = ref({

title: '',
slug: '',
tab_image: [],
header_image: [],
alt_tab_image: '',
alt_header_image: '',
type: '',
subtitle: '',
related_courses: [],
related_posts: [],

category_id: '',
})
    const articleByCategoryList = ref([])
    const { route } = useRouter()
    const { id } = route.value.params
    const UpdateBlogForm = ref(Form)
    const getNews = () => {
      if (UpdateBlogForm.value.type == 2) {
        UpdateBlogForm.value.course = ''
        UpdateBlogForm.value.category=null
        UpdateBlogForm.value.related_article = ''
        UpdateBlogForm.value.related_posts = []
        store
          .dispatch('blog/getAllNews')
          .then(response => {
            articleByCategoryList.value = response.data.data
          })
      }
    }
  
  
    const getCoursesByCategory = id => {
      getCourse.value = true
      const { type } = UpdateBlogForm.value
      UpdateBlogForm.value.course = ''
      UpdateBlogForm.value.related_article = ''
      UpdateBlogForm.value.related_posts = []
      store
        .dispatch('categories/getCoursesByCategory', {
          ' category_id': id,
        })
        .then(response => {
          response.data.data.forEach(el => {
            if (el.online == 1) {
              coursesList.value.push({
                id: el.id,
                name: `${el.name} Online`,
                link_id: el.link_id,
              })
            } else {
              coursesList.value.push({
                id: el.id,
                name: el.name,
                link_id: el.link_id,
              })
            }
          })
        })
        if(type==1)
        {

        
        store.dispatch('categories/getCategoryByArticle', { id })
        .then(response => {
          articleByCategoryList.value = response.data.data
        })
      }
    }
    //   const productId = productSlug.substring(id.lastIndexOf('-') + 1)

  
   
    store.dispatch('blog/Getblog', { id }).then(response => {
      console.log(response?.data.data)
      Form.value = response?.data.data

      store.commit('blog/GET_BLOG', response?.data.data)
      Form.value.related_courses = JSON.parse(response?.data.data.related_courses)
      Form.value.related_posts = JSON.parse(response?.data.data.related_posts)
      const CategoryId = response?.data.data.category_id

      getCoursesByCategory(CategoryId)
    })
    const removeSpace = target => {
      UpdateBlogForm.slug = target // LowerCase
        .toLowerCase() // LowerCase
        .replace(/\s+/g, '-') // space to -
        .replace(/&/g, '') // & to and
        .replace(/--/g, '-')
        .replace(/[.,]/g, '-')
        .replace(/[_]/g, '')
        .replace(/[!''""=]/g, '')
        .replace(/[!?%$^~*#()+]/g, '')
    }
    const Tabimage = ref('media/svg/files/blank-image.svg')
    const categoriesList = ref([])
    store.dispatch('categories/CategoryList')
      .then(response => {
        categoriesList.value = response.data
      })

    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      base64 => {
        refPreviewEl.value.src = base64
      },
    )
    const showList = ref([{
      id: 2,
      label: 'News',
    },
    {
      id: 1,
      label: 'Articles',
    },

    ])
    const { inputImageRendererTab } = useInputImageRenderer(
      refInputE2,
      base64 => {
        refPreviewE2.value.src = base64
      },
    )
    const UpdateBlogFormvalidate = ref()

    const save = () => {
      const formData = new FormData()

      UpdateBlogFormvalidate.value.validate().then(success => {
        if (success) {
          formData.append('title', UpdateBlogForm.value.title)
          formData.append('subtitle', UpdateBlogForm.value.subtitle)
          formData.append('slug', UpdateBlogForm.value.slug)
          formData.append('tab_image', UpdateBlogForm.value.tab_image)
          formData.append('header_image', UpdateBlogForm.value.header_image)
          formData.append('alt_tab_image', UpdateBlogForm.value.alt_tab_image)
          formData.append(' alt_header_image', UpdateBlogForm.value.alt_header_image)
          formData.append('type', UpdateBlogForm.value.type)
          formData.append('subtitle', UpdateBlogForm.value.subtitle)
          formData.append('details', UpdateBlogForm.value.details)
          formData.append('category_id', UpdateBlogForm.value.category_id)
          formData.append('_method', 'put')
          formData.append(
            'related_courses',
            JSON.stringify(UpdateBlogForm.value.related_courses),
          )
          formData.append(
            'related_posts',
            JSON.stringify(UpdateBlogForm.value.related_posts),
          )

          store.dispatch('blog/Updateblog', { id, formData })
            .then(response => {
              Vue.swal({
                title: 'تم التحديث ',
                icon: 'success',
                confirmButtonText: 'حسنا',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            }).catch(error => {
              Vue.swal({
                title: '',
                text: `${error.response.data.message}`,
                icon: 'error',
                confirmButtonText: 'حسنا',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
        }
      })
    }
    function example_image_upload_handler(blobInfo, success, failure, progress) {
      let xhr; let
        formData
      const token = localStorage.getItem('token')
      console.log('token', token)
      xhr = new XMLHttpRequest()
      xhr.withCredentials = false
      xhr.open('POST', 'https://api.ar.lpcentre.com/api/upload_image_tiny_mce')

      xhr.upload.onprogress = function (e) {
        progress(e.loaded / e.total * 100)
      }
      xhr.setRequestHeader('Authorization', `Bearer ${token}`)
      xhr.onload = function () {
        let json

        if (xhr.status === 403) {
          failure(`HTTP Error: ${xhr.status}`, { remove: true })
          return
        }

        if (xhr.status < 200 || xhr.status >= 300) {
          failure(`HTTP Error: ${xhr.status}`)
          return
        }

        json = JSON.parse(xhr.responseText)

        if (!json || typeof json.location !== 'string') {
          failure(`Invalid JSON: ${xhr.responseText}`)
          return
        }

        success(json.location)
      }

      xhr.onerror = function () {
        failure(`Image upload failed due to a XHR Transport error. Code: ${xhr.status}`)
      }

      formData = new FormData()
      formData.append('image', blobInfo.blob(), blobInfo.filename())

      xhr.send(formData)
    }
    return {
      example_image_upload_handler,
      refInputEl,
      getNews,
      id,
      refPreviewEl,
      refInputE2,
      refPreviewE2,
      inputImageRendererTab,
      Tabimage,
      UpdateBlogForm,
      inputImageRenderer,
      getCoursesByCategory,
      UpdateBlogFormvalidate,
      categoriesList,
      articleByCategoryList,
      Form,
      Editor,
      showList,
      getCourse,
      save,
      removeSpace,
      required,
      email,
      coursesList,
    }
  },

  components: {
    BCard,
    BMedia,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BMediaAside,
    BMediaBody,
QuillEditor,
    BForm,

    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    Editor,
    vSelect,

  },

  directives: {
    Ripple,
  },
}
</script>

  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  @import "@core/scss/vue/libs/quill.scss";
  @import "@core/scss/vue/pages/page-blog.scss";
  </style>
